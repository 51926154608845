import React from "react"
import Layout from "./components/Layout";
import { Row, Col } from "react-bootstrap";
import { graphql } from "gatsby";
// import AniLink from "gatsby-plugin-transition-link/AniLink";
import ProjectItem from "./components/ProjectItem";
// import {stockData} from "../json/data";

const projects = ({ data }) => {  
    return (
        <Layout>
            <Row id="banner" className="min-100 relative bg-taieri"
            style={{backgroundPosition: '10% 10%', backgroundSize: '900%' }}>
                <Col xl={12} className="align-left p-5 mt-5">
                  <h1 className="site-title">
                      featured projects.
                  </h1>
                </Col>
            </Row>

          {/* {stockData.map((data, key) => {
            return (
              <div key={key}>
                <ProjectItem 
                projectTitle={data.title}
                projectSubTitle={data.subTitle}
                projectDesc={data.description}
                imgSrc={data.img}
                linkPath={data.pageLink}
                />
              </div>
            );
          })} */}
          
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <div key={node.id}>
                <ProjectItem 
                projectTitle={node.frontmatter.title}
                projectSubTitle={node.frontmatter.elements}
                projectDesc={node.frontmatter.description}
                imgSrc={node.frontmatter.imgThumb}
                linkPath={node.fields.slug}
                />
              </div>
            ))}
      </Layout>
    )
}

export default projects;

export const query = graphql`
  query {
    allMarkdownRemark(sort: {fields: frontmatter___id, order: ASC}, limit: 6) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            title
            tagline
            elements
            description
            imgThumb
          }
        }
      }
    }
  } 
`